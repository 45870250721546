import React from 'react';
import './Header.css';


const Footer = () => {
    return(
        <div style={{width:'100%',display:'inline-block',color:"black"}}>
            <hr/>
            <div>
                <center>
                    <h6>&copy; De Majestic Garden Hotels&nbsp;({new Date().getFullYear()})</h6>
                    <i className="fa fa-map-marker"></i> &nbsp;Address:  Plot 203/204 Ugwu Orji Layout, Adjacent Peace Mass Transit,  Owerri North LGA, Imo State, Nigeria
                    <p><i className="fa fa-phone"></i>&nbsp;+234 907 612 7014 | +234 803 754 2524 | +234 905 258 3702</p>
                    <div>
                        <span classname="twitr">
                            <a href="twitter.com">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </span>&nbsp; &nbsp;
                    
                    
                        <span classname="facebk">
                            <a href="facebook.com">
                                <i class="fab fa-facebook"></i>
                            </a>
                            
                        </span>&nbsp; &nbsp;
                    
                    
                        <span classname="insta">
                            <a href="facebook.com">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </span>
                    </div>
                </center>
                
            </div>
        </div>
    )
}

export default Footer;