import React,{Component} from 'react';
import './Home.css';
import Header from '../Header';




const slideUrl = "https://demajesticapi.onrender.com/homecarousel";

class HomeCarousel extends Component {
    constructor(props) {
        super(props);
        console.log (">>>Inside constructor")
        this.state={
            images:'',
            
        }
    }
    
    renderSlide = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <div id ="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[0]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[1]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[2]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[3]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[4]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[5]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[6]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[7]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.hotelGallery_carousel[8]} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                                                        
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
    
                    </div>
                )
            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }            
        
    }
    render(){
        console.log (">>>Inside render",this.state)
        return(
            <>
                <Header/>
                <div className="slider">
                    {this.renderSlide(this.state.images)}
                </div>
                <center>
                    <div className="services">
                        <br/><h3>Our Services</h3><br/>
                        <div className="row margin">
                            <div className="col-12">
                                <i className="fa fa-cutlery fa-2x mb-4" aria-hidden="true"></i>
                                <h5>24 Hours Restaurant</h5>
                                <p className="paragr">
                                    Enjoy intercontinental dishes
                                    24 hours in restaurant and room services
                                </p>
                            </div>
                            <div className="col-12">
                                <i className="fa fa-beer fa-2x mb-4" aria-hidden="true"></i>
                                <h5>Exclusive Bar</h5>
                                <p className="paragr">
                                    Drink to that moment and watch live games with 
                                    your friends and family
                                </p>
                            </div>
                            <div className="col-12">
                                <i className="fa fa-calendar fa-2x mb-4" aria-hidden="true"></i>
                                <h5>Event Center</h5>
                                <p className="paragr">
                                    We have solutions to your banqueting needs in 
                                    our large capacity Halls 
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    
                </center>
            </>
            
        )
    }

    componentDidMount(){
        console.log (">>>Inside didMount")
        fetch(slideUrl, {method:'GET'} )
        .then((res) => res.json())
        .then((data) => {
            this.setState({images:data})
        })
    }
}

export default HomeCarousel;

