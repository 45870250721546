import React, {Component} from 'react';
import './Reservation.css';
import moment from 'moment';
import NumberFormat from 'react-number-format'; 
import {Link} from 'react-router-dom';
import Header from '../Header';




const orderRoom = 'https://demajesticapi.onrender.com/bookNow'

class Checkincheckout extends Component {

    constructor(props) {
        super(props);
        console.log (">>> Inside ResContructor", props)

        this.state={
            id:Math.floor(Math.random()*100000),
            fname: "",
            lname: "",
            phone: "",
            email: "",
            country: "",
            note: "",
            roomType:this.props.match.params.rmName,
            arrival: "",
            departure:"",
            roomrate:"",
            stay:"",
            cost:0

        }

    }


    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

   


    async handleSubmit() {
        
        try {
            let result = await fetch(orderRoom, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    this.state
                )
            });
            
            console.log('result>  ' + result)
            alert("Booking Details Successfully Submitted. Your reference number is important")
            this.props.history.push('/')   
            
            
        } catch(e) {
            console.log(e)
        }
    }


          
    render() {
        console.log (">>> Inside Resdetails", this.state)
        return(
            <>
                <Header/>
                <div className="container">
                    <div className="row">
                        <div className="closing">
                            <Link to={`/booking/`}><button type="button" class="btn-close" aria-label="Close"></button></Link>
                        </div>
                        <span className="col-6 guestformat">
                            <div className="mb-3">
                                <h5>Guest Details</h5>
                                <hr/>
                            </div>
                            <form>
                                <div className="row">
                                    <div className ="col-6">
                                        <label className='form-label'>First Name</label>
                                        <input type="text" className="form-control mb-3" name="fname" value={this.state.fname} onChange={this.handleChange}/>
                                        <label className='form-label'>Last Name</label>
                                        <input type="text" className="form-control mb-3" name="lname" value={this.state.lname} onChange={this.handleChange}/>
                                        <label className='form-label'>Phone Number</label>
                                        <input type="number" className="form-control mb-3" name="phone" value={this.state.phone} onChange={this.handleChange}/>
                                    </div>
                                    <div className ="col-6">
                                        <label className='form-label'>Email</label>
                                        <input type="email" className="form-control mb-3" name="email" value={this.state.email} onChange={this.handleChange}/>
                                        <label className='form-label'>Country</label>
                                        <input type="text" className="form-control mb-3" name="country" value={this.state.country} onChange={this.handleChange}/>
                                        <label className='form-label'>Special Request</label>
                                        <textarea className="form-control mb-3" rows="4" name="note" value={this.state.note} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                
                            </form>
                            <div>
                                <center><p><b>Booking Reference Number: {this.state.id}</b></p></center>
                            </div>
                            <center><button className="btn btn-primary" onClick={ () => this.handleSubmit() }>Proceed</button></center>
                        </span>
                        <span className="col-5 roomdetformat container">
                            
                            <div>
                                <h5>Booking Summary</h5>
                                
                            </div>
                            <div className="mb-3">
                                <div>
                                    <hr/>
                                    <img src={sessionStorage.getItem('images')} style={{width:"307px", height:"250px"}} alt="roomspix"/>
                                </div>
                                <div className="mb-3">
                                    <p>Room Type:&nbsp;<b>{this.state.roomsinfo}&nbsp;is&nbsp;<NumberFormat value={this.state.roomrate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</b></p>
                                </div>
                            </div>
                            <div className="mb-3">
                                <p>Check In Date:&nbsp;<b>{this.state.arrival}</b></p>
                                <p>Check Out Date:&nbsp;<b>{this.state.departure}</b></p>
                            </div>
                            <div className="mb-3">
                                <p>No of Nights:&nbsp;<b>{this.state.stay}</b></p>
                            </div>
                            <hr/>
                            
                            <div className="resatnImage mb-3">
                                <b><p>Total&nbsp;<NumberFormat value={this.state.cost} displayType={"text"} thousandSeparator={true} prefix={"NGN"}/>.00</p></b>
                            </div>
                           
                            <hr/>
                        </span>
                        
                    </div>
                    
                </div>
            </>
                
        )
    }
    

    //On Page Load>>>>>>>
    componentDidMount(){
        console.log (">>> Inside ResDidMount", this.state)

        let checkindate = moment(`${sessionStorage.getItem('checkin')}`).format('MMM DD YYYY')
        this.setState({arrival:checkindate})

        let checkoutdate = moment(`${sessionStorage.getItem('checkout')}`).format('MMM DD YYYY')
        this.setState({departure:checkoutdate})

        let rmPrice = parseInt(`${sessionStorage.getItem('rate')}`)
        this.setState({roomrate:rmPrice})

        var thisDate = sessionStorage.getItem('checkout');
        var endDate = moment(thisDate);
        sessionStorage.setItem('endate',endDate);
        var checkinDate = moment(sessionStorage.getItem('checkin'));
        sessionStorage.setItem('chkindate',checkinDate)
        var diff = endDate.diff(checkinDate);
        var comp = Math.ceil(diff/(1000*3600*24));
        sessionStorage.setItem('difdate',comp);
        var ctotal = sessionStorage.getItem('difdate');
        this.setState({stay:ctotal})

        var obj = this.state
        obj.cost= rmPrice * ctotal

    }

   
} 
export default Checkincheckout;

