import React,{Component} from 'react';
import Header from '../Header';
import './login.css'

const loginUrl = "https://zarvichusers.herokuapp.com/api/auth/login";


class Login extends Component {
    constructor(props){
        super(props)

        this.state={
            password:'',
            email:'',
            message:''

        }
    }

    handleSubmit = () => {
        fetch(loginUrl,{
            method:'POST',
            headers:{
                'accept':'application/json',
                'content-type':'application/json'
            },
            body:JSON.stringify(this.state)
        })

        .then((res) => res.json())
        .then((data) => {
            if(data.auth ===  false){
                this.setState({message:data.token});
            }else{
                localStorage.setItem('ltk',data.token)
                
                this.props.history.push('/')
            }
        })
    }
   

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    render(){
        return(
            <>
                <Header/>
                <div className="container mt-3">
                    <center>
                        <h5> Login</h5>
                    </center>               
                    <h3 style={{color:'red'}}>{this.state.message}</h3>
                                   
                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">
                            Email
                        </label>
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" name="email"
                        value={this.state.email} onChange={this.handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label for="inputPassword" className="form-label">
                            Password
                        </label>
                        <input type="password" className="form-control" id="inputPassword" placeholder="Input Password" name="password"
                        value={this.state.password} onChange={this.handleChange}/>
                            
                    </div>
               
                    <center>
                        <button type="button" class="size btn btn-outline-primary" onClick={this.handleSubmit}>
                            Login
                        </button>
                    </center>
                           
                </div>
                    
                
            </>
        )
    }
}

export default Login;