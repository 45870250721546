import React,{Component} from 'react';
import './Home.css';
import {Link} from 'react-router-dom';
import NumberFormat from 'react-number-format'; 


const roomsurl = "https://demajesticapi.onrender.com/rooms"

class RoomsDisplay extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            rooms:''
        }
    }


    renderRooms = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <>
                        <div className="card">
                            <img src={item.room_images} className="card-img-top" alt="roomImages"/>
                            <div className="card-body">
                                <center>
                                    <h4 className="card-title">{item.roomtype_Name}</h4>
                                </center>
                                
                            </div>
                            <div className="overlay"><br/>
                                <h5 className="note">{item.roomtype_Name}</h5>
                                <hr style={{color:"gold"}}/><br/>
                                <center>
                                    <p style={{fontFamily:"Georgia, 'Times New Roman', Times, serif"}}>{item.room_description}</p>
                                    <b><br/>
                                        <p style={{fontFamily:"Georgia, 'Times New Roman', Times, serif", fontSize:"20px", color:"gold"}}><NumberFormat value={item.room_rate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</p>
                                    </b>
                                    <hr style={{color:"gold"}}/><br/>
                                    <Link to={`/rooms/${item.roomtype_id}`} key={item.roomtype_id}>                                    <span><button type="button" class="btn btn-outline-warning">{item.facilies[0].facilty}</button></span>
                                        <span><button type="button" class="btn btn-outline-warning">{item.facilies[1].facilty}</button></span>
                                        <span><button type="button" class="btn btn-outline-warning">{item.facilies[2].facilty}</button></span>
                                        <span><button type="button" class="btn btn-outline-warning">{item.facilies[3].facilty}</button></span>
                                        <span><button type="button" class="btn btn-outline-warning">{item.facilies[4].facilty}</button></span>
                                    </Link>
                                    <br/><br/>
                                    <p>
                                        <Link to={`/rooms/${item.roomtype_id}`} key={item.roomtype_id} style={{fontFamily:"Georgia, 'Times New Roman', Times, serif", fontSize:"20px", textDecoration:"none", color:"gold"}}> View More</Link>
                                    </p>
                                </center>
                            </div>
                        </div>
                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }


    render(){
        
        return(
            <>
                <div className="container">
                    <center><h3 className="text">Featured Rooms</h3></center>
                    <div className="row">
                        <div className ="col">
                            {this.renderRooms(this.state.rooms)}
                        </div>
                       
                    </div>
                    
                </div>
            
            </>
            
        )
    }

    componentDidMount(){
        
        fetch(roomsurl, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({rooms:data})
        })

    }
}

export default RoomsDisplay;