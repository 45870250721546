import React,{Component} from 'react';
import Header from '../Header';

const registerUrl = "https://zarvichusers.herokuapp.com/api/auth/register";


class Register extends Component {
    constructor(props){
        super(props)

        this.state={
            name:'',
            phone:'',
            email:'',
            password:''

        }
    }

    handleSubmit = () => {
        fetch(registerUrl,{
            method:'POST',
            headers:{
                'accept':'application/json',
                'content-type':'application/json'
            },
            body:JSON.stringify(this.state)
        })

        .then(this.props.history.push('/login'))
    }
   

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }
    render(){
        return(
            <>
                <Header/>
                <div className="container mt-3">
                    <center>
                        <h5> Register</h5>
                    </center>               

                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">
                            Name
                        </label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" name="name"
                        value={this.state.name} onChange={this.handleChange}/>
                    </div>

                   <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">
                            Email
                        </label>
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" name="email"
                        value={this.state.email} onChange={this.handleChange}/>
                    </div>

                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">
                            Phone
                        </label>
                        <input type="tel" className="form-control" id="exampleFormControlInput1" placeholder="Enter Phone Number" name="phone"
                        value={this.state.phone} onChange={this.handleChange}/>
                    </div>

                    <div className="mb-3">
                        <label for="inputPassword" className="form-label">
                            Password
                        </label>
                        <input type="password" className="form-control" id="inputPassword" placeholder="Enter Password" name="password"
                        value={this.state.password} onChange={this.handleChange}/>
                    </div>
                       
                    <center>
                        <button type="button" class="size btn btn-outline-primary" onClick={this.handleSubmit}>
                            Register
                        </button>
                    </center>
                           
                </div>
           
            </>
        )
    }
}

export default Register;