import React,{Component} from 'react';
import './Reservation.css';
import axios from 'axios';
import {Link} from 'react-router-dom';
import NumberFormat from 'react-number-format'; 
import Header from '../Header';


const listUrl = "https://demajesticapi.onrender.com/rooms";


class Listing extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside LCOnstructor")

        this.state={
            roomDetail:''
                        
        }
    }
    
   

    renderList = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <>
                        <div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <br/>
                                        <img src={item.room_images} alt="fc" className="image"/>
                                        <div className="imgtext">
                                            <div className="imgbut">
                                                <p>From</p>
                                                <p style={{fontFamily:"Georgia, 'Times New Roman', Times, serif"}}><h5><NumberFormat value={item.room_rate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</h5></p>
                                                <Link to={`/rooms/${item.roomtype_id}`} key={item.roomtype_id}>
                                                    <button className="btn btn-dark"> More Info</button>
                                                </Link>
                                            </div>
                                            <h3>{item.roomtype_Name}&nbsp; <NumberFormat value={item.room_rate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</h3>
                                            <p>{item.facilies[0].facilty_description}</p>
                                            <p>Beds:&nbsp;{item.facilies[0].facilty}</p>

                                            

                                            <hr/>
                                            

                                            
                                            <span><img src={item.facilies[0].facility_Image} className="icons" alt="fc"/></span>
                                            <span><img src={item.facilies[1].facility_Image} className="icons" alt="fc"/></span>
                                            <span><img src={item.facilies[2].facility_Image} className="icons" alt="fc"/></span>
                                            <span><img src={item.facilies[3].facility_Image} className="icons" alt="fc"/></span>
                                            <span><img src={item.facilies[4].facility_Image} className="icons2" alt="fc"/></span>
                                            
                                        </div>

                                        
                                        
                                    </div>
                                </div> 
                                <hr/>
                                        
                                

                                
                                
                            </div>

                        </div>
                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }

    render(){
        console.log (">>> Inside LRender", this.state)
        return(
            <>
                <Header/>
                <div className="container">
                    {this.renderList(this.state.roomDetail)}
                    
                </div>
            
            </>
            
        )
    }

    async componentDidMount(){
        console.log (">>> Inside LdidMount")
        const response = await axios.get(listUrl)
        this.setState({roomDetail:response.data})
      
      
    }
}

export default Listing;

